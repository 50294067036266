// @flow
import { List } from 'semantic-ui-react';
import React from 'react';

import { StyledHierarchyList } from '../../../common-utils/styledComponents';
import { highlightMatchingPortionInTextAndReturnSpan } from '../../home-page/hierarchy-view/utils';

export const HierarchyLevel = ({
  data,
  level,
  hierarchyLevels,
  openStates,
  toggleOpen,
  highlightTerm,
  LeafComponent,
  parentData = {},
}) => {
  if (level >= hierarchyLevels.length) {
    return (
      <LeafComponent
        context={{
          highlightTerm,
          hierarchyLevels,
        }}
        parentData={parentData}
      />
    );
  }

  const currentLevel = hierarchyLevels[level];
  return (
    <StyledHierarchyList divided={true} level={level} selection={true}>
      {data.map((item) => {
        const parentKey = parentData?.keySoFar ?? '';
        const key = `${parentKey}-${currentLevel.name}-${item.id}`;
        const isOpen = openStates[key];
        const newParentData = {
          ...parentData,
          keySoFar: key,
          [currentLevel.name]: { ...item },
        };
        return (
          <List.Item
            key={key}
            onClick={(e) => {
              e.stopPropagation();
              toggleOpen(key);
            }}
            style={{ cursor: 'pointer' }}
          >
            <List.Icon
              color={currentLevel.color}
              name={isOpen ? `${currentLevel.folderIcon} open` : currentLevel.folderIcon}
            />
            <List.Content>
              <List.Header>
                {highlightMatchingPortionInTextAndReturnSpan(item.name, highlightTerm)}
              </List.Header>
              <List.Description>
                {highlightMatchingPortionInTextAndReturnSpan(item.id, highlightTerm)}
              </List.Description>
              {isOpen && (
                <HierarchyLevel
                  LeafComponent={LeafComponent}
                  data={item.children}
                  hierarchyLevels={hierarchyLevels}
                  highlightTerm={highlightTerm}
                  level={level + 1}
                  openStates={openStates}
                  parentData={newParentData}
                  toggleOpen={toggleOpen}
                />
              )}
            </List.Content>
          </List.Item>
        );
      })}
    </StyledHierarchyList>
  );
};
