// @flow
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { STREAMING_PROJECTS_API } from '@streaming-projects/constants';

const getInvalidatesTagsFunc = (arrayOfTagsToInvalidate) => (result, error) => {
  // Don't invalidate the cache if a update conflict occurs (HTTP 409).
  // This prevents the latest `version` being fetched via a requery and
  // stops further updates until the browser is refreshed by the user
  if (error?.status === 409) {
    return [];
  }
  return arrayOfTagsToInvalidate;
};

export const streamingProjectsApi = createApi({
  reducerPath: STREAMING_PROJECTS_API,
  baseQuery: fetchBaseQuery({
    baseUrl: `${window?._env_?.API_URL}/api/v2/`,
    prepareHeaders: (headers) => {
      const token = JSON.parse(localStorage.getItem('okta-token-storage')).accessToken.accessToken;
      headers.set('Authorization', token ? `Bearer ${token}` : '');
      return headers;
    },
  }),
  tagTypes: ['StreamingProjects'],
  endpoints: (builder) => {
    return {
      // **************** Streaming Projects Related ***************** ///

      getStreamingProjectsHierarchy: builder.query({
        query: ({ query, limit }) => {
          return {
            url: 'views/orghierarchy',
            params: { query, limit },
          };
        },
        providesTags: ['StreamingProjects'],
      }),

      // Add Org
      addOrganization: builder.mutation({
        query: (body) => {
          return {
            url: '/orgs',
            method: 'POST',
            body,
          };
        },
        invalidatesTags: getInvalidatesTagsFunc(['StreamingProjects']),
      }),

      // Get SP Org
      getSPOrgLevelView: builder.query({
        query: ({ orgId }) => {
          return {
            url: `views/orgs/${orgId}?query=HOME`,
          };
        },
        providesTags: ['StreamingProjects'],
      }),

      // End of Config
    };
  },
});

export const {
  // Hierarcy Related
  useGetStreamingProjectsHierarchyQuery,
  // Orgs Related
  useAddOrganizationMutation,
  useGetSPOrgLevelViewQuery,
} = streamingProjectsApi;
