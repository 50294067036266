// @flow
export const LINK = 'link';

export const BACKEND_FIELDNAMES = {
  NAME: 'name',
  ID: 'id',
  LINK: 'link',
  SFDC_ORG_NAME: 'sfdc_org_name',
  SFDC_ORG_ID: 'sfdc_org_id',
  RATE_CARD: 'rate_card',
  ACCOUNT_NAME: 'account_name',
  ACCOUNT_ID: 'account_id',
};
