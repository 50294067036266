// @flow
export const GRID_HEADERS = {
  ORGANIZATION_NAME: 'Organization Name',
  ORGANIZATION_LIST: 'Organization List',
  ORGANIZATION_ID: 'Organization Id',
  LINK: 'link',
  RATE_CARD: 'Rate Card',
};

export const GRID_FIELDNAMES = {
  ORGANIZATION_ID: 'organizationId',
  ORGANIZATION_NAME: 'organizationName',
  ORGANIZATION_LIST: 'organizationList',
  LINK: 'link',
  RATE_CARD: 'rateCard',
};

export const ADD_ORGANIZATION = 'Add Organization';
