// @flow
import { RATE_CARD_VERSION_4_11_2024 } from '@src/constants';

import { BACKEND_FIELDNAMES } from './enums';

export const getRCVersionOptionsFromRCs = (rcVersions, isRenewal) => {
  const rcVersionsOptions = [];
  for (const rc of rcVersions) {
    if (isRenewal && rc.name === RATE_CARD_VERSION_4_11_2024) {
      continue;
    }

    const actionable = rc?.actionable ?? true;
    rcVersionsOptions.push({
      name: rc.name,
      display_name: rc.label,
      shouldNotBeSelectable: !actionable,
    });
  }
  return rcVersionsOptions;
};

export const getOrgDefaultValues = (account) => {
  return {
    [BACKEND_FIELDNAMES.ACCOUNT_ID]: account[BACKEND_FIELDNAMES.ID],
    [BACKEND_FIELDNAMES.ACCOUNT_NAME]: account[BACKEND_FIELDNAMES.NAME],
  };
};
